import React, { useState, useEffect } from 'react';
import { AccountInfo } from '@azure/msal-browser';

const AccountSelection = ({ msalInstance }) => {
    const [accounts, setAccounts] = useState<AccountInfo[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<AccountInfo | null>(null);

    useEffect(() => {
        const allAccounts = msalInstance.getAllAccounts();
        setAccounts(allAccounts);
        // If there's only one account, set it as the active account
        if (allAccounts.length === 1) {
            msalInstance.setActiveAccount(allAccounts[0]);
            setSelectedAccount(allAccounts[0]);
        }
    }, [msalInstance]);

    const handleAccountSelection = (account: AccountInfo) => {
        msalInstance.setActiveAccount(account);
        setSelectedAccount(account);
    };

    if (accounts.length <= 1) {
        return null; // No need for UI if there's one or no account
    }

    return (
        <div>
            <h3>Select an Account</h3>
            <ul>
                {accounts.map((account) => (
                    <li key={account.homeAccountId}>
                        <button onClick={() => handleAccountSelection(account)}>
                            {account.username}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AccountSelection;
